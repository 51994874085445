//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-784:_7676,_3236,_7244,_2128,_4136,_5484,_5776,_4496;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-784')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-784', "_7676,_3236,_7244,_2128,_4136,_5484,_5776,_4496");
        }
      }catch (ex) {
        console.error(ex);
      }