//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-784:_9564,_9296,_4184,_7612,_3036,_6580,_6052,_2872;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-784')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-784', "_9564,_9296,_4184,_7612,_3036,_6580,_6052,_2872");
        }
      }catch (ex) {
        console.error(ex);
      }